<!--<a class="skiplink" href="#content">Ga naar inhoud</a>-->

<!-- Bar -->
<section class="topbar-wrapper" [cdkTrapFocus]="menuActive()">
  <div class="topbar" #topbar [class.is--fixed]="fixed()" [class.is--open]="menuActive()">
    <div class="container-fluid px-lg-4 topbar__inner">
      <a class="topbar__logo" [routerLink]="'/'" (click)="closeOverlay.emit()">
        <img [src]="activePortal()?.logoUrl || '/assets/gfx/logos/logo-hedon.svg'" [alt]="activePortal()?.title || 'Hedon Zwolle'" />
      </a>
      <nav class="topbar__actions">
        @if (!searchActive()) {
          <a [routerLink]="['/zoeken']" class="button button-icon button--surface" (click)="closeOverlay.emit()">
            <fstr-icon anchor="line-icon-search"></fstr-icon>
          </a>
        } @else {
          <button class="button button-icon button--surface" (click)="closeSearch()">
            <fstr-icon anchor="icon-search-close"></fstr-icon>
          </button>
        }

        <a class="button button--primary d-none d-sm-inline-flex" [routerLink]="'/'" [fragment]="'programma'" (click)="closeOverlay.emit()">
          {{ 'programma-knop-tekst' | fromDictionary }}
        </a>

        <button class="menu-toggle button button--surface" [class.is--active]="menuActive()" (click)="toggleOverlay.emit()" type="button">
          @if (menuActive()) {
            <span>Sluiten</span>
          } @else {
            <span>Menu</span>
          }
          <span class="hamburger" [class.is--active]="menuActive()">
            <span class="hamburger__lines"></span>
          </span>
        </button>
      </nav>
    </div>
  </div>

  <!-- Overlay -->
  @if (menuActive()) {
    <nav class="nav-overlay" [@fadeAnimation]>
      <div class="nav-overlay__scroll-container custom-scrollbar py-5">
        <div class="container">
          <div class="row gutter-y-5">
            <!-- featured -->
            <ul class="col-md-12 nav-list">
              @for (page of featuredItems(); track page) {
                <li class="nav-list__item">
                  <fstr-link
                    [button]="{
                      text: page.title,
                      url: page.url || '',
                      routerLink: page.routerLink,
                      fragment: page.fragment,
                    }"
                    [routerLinkActive]="'is--active'"
                    [routerLinkActiveOptions]="{ exact: page.url === '/' }"
                    (click)="toggleOverlay.emit()"
                    [showText]="true"
                    [cssClasses]="'link-hover-mark h1 mb-0'"
                  ></fstr-link>
                </li>
              }
            </ul>

            <!-- other nav -->
            <ul class="col-md-12 nav-list">
              @for (page of menuItems(); track page) {
                <li class="nav-list__item">
                  <fstr-link
                    [button]="{
                      text: page.title,
                      url: page.url || '',
                      routerLink: page.routerLink,
                      fragment: page.fragment,
                    }"
                    [routerLinkActive]="'is--active'"
                    [routerLinkActiveOptions]="{ exact: page.url === '/' }"
                    (click)="toggleOverlay.emit()"
                    [showText]="false"
                    [cssClasses]="'link-hover-mark fs-body-md'"
                  >
                    <span>{{ page.title }}</span>
                  </fstr-link>
                </li>
              }
            </ul>
          </div>

          <section class="mt-6 mt-lg-8">
            <div class="meta-bar mb-3">
              <ng-content select="[partner-list]"></ng-content>
              <ng-content select="[social-list]"></ng-content>
            </div>

            <!-- other nav -->
            <ul class="meta-nav-list">
              @for (page of metaMenu(); track page) {
                <li class="meta-nav-list__item">
                  <fstr-link
                    [button]="{
                      text: page.title,
                      url: page.url || '',
                      routerLink: page.routerLink,
                      fragment: page.fragment,
                    }"
                    [routerLinkActive]="'is--active'"
                    [routerLinkActiveOptions]="{ exact: page.url === '/' }"
                    (click)="toggleOverlay.emit()"
                    [showText]="false"
                    [cssClasses]="'link-muted-surface'"
                  >
                    <span>{{ page.title }}</span>
                  </fstr-link>
                </li>
              }
              <li class="meta-nav-list__item">
                <button type="button" class="link-muted-surface" (click)="openCookiePreferences.emit()">
                  {{ 'cookie-settings' | fromDictionary }}
                </button>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </nav>
  }
</section>

@if (showProgramCTAButton()) {
  <div class="fixed-cta-button d-sm-none" [class.background-gradient]="!menuActive()" [@fadeAnimation]>
    <a class="button button--sm button--primary w-100" [routerLink]="'/'" [fragment]="'programma'" (click)="closeOverlay.emit()">
      <span>{{ 'programma-knop-tekst' | fromDictionary }}</span>
    </a>
  </div>
}
