import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { getSwitcherPortalsLoaded, LoadSwitcherPortals } from '../store';

export const SwitcherPortalsGuard: CanActivateFn = route => {
  const store = inject(Store);
  return store
    .select(getSwitcherPortalsLoaded)
    .pipe(
      tap(loaded => {
        if (!loaded) {
          store.dispatch(LoadSwitcherPortals());
        }
      }),
      filter(loaded => loaded),
      take(1)
    )
    .pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
};
