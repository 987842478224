import { provideHttpClient, withInterceptorsFromDi, withFetch } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { BrowserModule, provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { provideRouter, RouteReuseStrategy, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { CookieConfig, ProvideCookiesNgrx } from '@teamfoster/sdk/cookie-ngrx';
import { ProvideDictionaryNgrx } from '@teamfoster/sdk/dictionary-ngrx';
import { ProvideIcons } from '@teamfoster/sdk/icon';
import { ImageSharpConfig, ProvideOptimisedImageProcessor } from '@teamfoster/sdk/image';
import { ProvideMenuNgrx } from '@teamfoster/sdk/menu-ngrx';
import { environment } from 'src/environments/environment';
import { routes } from './app.routes';
import { reducers, metaReducers, appEffects, CustomSerializer } from './store';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { ProvidePortals } from './portals/portals.providers';
import { ProvideSocialsNgrx } from './social-media/socials.providers';
import { registerLocaleData } from '@angular/common';
import localeNl from './nl';
import { AppRouteReuseStrategy } from './app.route-reuse-strategy';
import { ImageSettings } from './imageSettings';

const cookieConfig: CookieConfig = {
  analyticsCode: 'UA-0000000-0',
  strippedContentPlaceholder: 'Deze inhoud wordt niet getoond omdat je geen marketing cookies hebt geaccepteerd',
  buttonSettings: 'Instellingen',
  buttonAcceptAll: 'Accepteer alle',
  buttonClose: 'Sluiten',
  strippedContentButton: 'Cookie-instellingen wijzigen',
  types: {
    analytical: true,
    functional: true,
    marketing: true,
  },
  showSettings: true,
};

registerLocaleData(localeNl, 'nl');

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(
      routes,
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'disabled' })
    ),

    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
    provideStore(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    ProvideSocialsNgrx({}),
    provideEffects(appEffects),
    ProvidePortals(),
    provideRouterStore({ serializer: CustomSerializer }),
    provideStoreDevtools({ maxAge: 25, logOnly: environment.production }),
    ProvideDictionaryNgrx({}),
    ProvideMenuNgrx({}),
    ProvideIcons({ spritesheetUrl: 'assets/dist/svg/symbol/svg/sprite.symbol.svg' }),
    ProvideCookiesNgrx(cookieConfig),
    ProvideOptimisedImageProcessor(ImageSharpConfig, ImageSettings),
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: true,
      })
    ),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    { provide: LOCALE_ID, useValue: 'nl' },
  ],
};
