import { Component, OnInit, Input, input } from '@angular/core';

@Component({
  selector: 'app-loader-indicator',
  templateUrl: './loading-indicator.component.html',
  standalone: true,
  styleUrls: ['./loading-indicator.component.scss'],
})
export class LoaderIndicatorComponent {
  loading = input<boolean>(false);
  type = input<'flux' | 'standalone'>('flux');
  
  constructor() {}
}
