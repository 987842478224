<aside class="social-channel">
  @if (title(); as title) {
    <h3 class="social-channel__title text-on-surface">{{ title }}</h3>
  }
  <ul class="follow-button-list">
    @for (item of channels(); track item) {
      <li class="follow-button-list__item">
        <a [href]="item.url" target="_blank" class="button button-link button--light">
          <span class="sr-only">{{ item.title }}</span>

          <!--@if (item.imageUrl) {
            <img [src]="item.imageUrl" [alt]="item.title" />
          }-->

          @if (item.icon) {
            <fstr-icon [anchor]="item.icon" [title]="item.title"></fstr-icon>
          }
        </a>
      </li>
    }
  </ul>
</aside>
