import { createReducer, on } from '@ngrx/store';
import { Portal } from '../../models';
import * as actions from '../actions/portal-switcher.action';
import { ToEntities } from '@teamfoster/sdk/array-utility';

export interface PortalSwitcherState {
  entities: { [id: number]: Partial<Portal> };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: PortalSwitcherState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const PortalSwitcherReducer = createReducer(
  initialState,
  on(actions.LoadSwitcherPortals, (state: PortalSwitcherState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(actions.LoadSwitcherPortalsSuccess, (state: PortalSwitcherState, { portals }) => {
    const entities = ToEntities(portals, 'id');
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(actions.LoadSwitcherPortalsFail, (state: PortalSwitcherState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getSwitcherPortalEntities = (state: PortalSwitcherState) => state.entities;
export const getSwitcherPortalsLoading = (state: PortalSwitcherState) => state.loading;
export const getSwitcherPortalsLoaded = (state: PortalSwitcherState) => state.loaded;
