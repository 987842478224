import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromPortals from '../reducers/portal-switcher.reducer';

export const getSwitcherPortalsState = createSelector(fromFeature.getPortalState, (state: fromFeature.PortalState) => state.portalSwitcher);

export const getSwitcherPortalEntities = createSelector(getSwitcherPortalsState, fromPortals.getSwitcherPortalEntities);
export const getSwitcherPortalsLoaded = createSelector(getSwitcherPortalsState, fromPortals.getSwitcherPortalsLoaded);
export const getSwitcherPortalsLoading = createSelector(getSwitcherPortalsState, fromPortals.getSwitcherPortalsLoading);

export const getAllSwitcherPortals = createSelector(getSwitcherPortalEntities, entities => {
  return Object.keys(entities).map(id => entities[+id]);
});
