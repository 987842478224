import { Injectable, inject, InjectionToken } from '@angular/core';
import { Portal } from '../models/portal.model';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs';
import { BASE_URL } from '@teamfoster/sdk/ssr';

@Injectable()
export class PortalsService {
  private http = inject(HttpClient);
  private BASE_URL = inject(BASE_URL, { optional: true }) || '';

  getActivePortal(portalname: string = '') {
    return this.http.get<Portal>(`${this.BASE_URL}api/portal/active?portalname=${portalname}`).pipe(
      catchError(err => {
        console.error(err);
        throw err;
      })
    );
  }

  getThemeSwitcherPortals() {
    return this.http.get<Partial<Portal>[]>(`${this.BASE_URL}api/portals/switcher`);
  }
}
