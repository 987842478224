import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { getActivePortal, getActivePortalLoaded, LoadActivePortal } from '../store';

import { routes as landingPageRoutes } from '../../landing-pages/landing-pages.routes';
import { routes as homeRoutes } from '../../home/home.routes';

export const ActivePortalGuard: CanActivateFn = route => {
  const store = inject(Store);
  const router = inject(Router);

  return store
    .select(getActivePortalLoaded)
    .pipe(
      withLatestFrom(store.select(getActivePortal)),
      tap(([loaded, portal]) => {
        if (!loaded) {
          const name = route.params['portalName'] || route.data['portalName'];
          store.dispatch(LoadActivePortal({ name }));
        } else {
          // Set the home route to the home module
          const routes = [...router.config];
          const homeRouteIndex = router.config.findIndex(a => a.path === '' && a.pathMatch === 'full');
          const landingPageIndex = router.config.findIndex(a => a.path === '' && a.pathMatch === 'prefix');

          if (portal?.id === 1 && homeRouteIndex > landingPageIndex) {
            // Swap route position
            routes[homeRouteIndex] = routes.splice(landingPageIndex, 1, routes[homeRouteIndex])[0];
            router.resetConfig(routes);

            if (route.url.length === 0) {
              router.navigate(route.url);
            }
          }
        }
      }),
      filter(([loaded]) => loaded),
      take(1)
    )
    .pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
};
