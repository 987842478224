@if (loading() && type() === 'flux') {
  <div class="page-loader">
    <div class="page-loader__progression type--flux"></div>
  </div>
}

@if (loading() && type() === 'standalone') {
  <div class="loader">
    <div class="cube"><div class="cube__inner"></div></div>
    <div class="cube"><div class="cube__inner"></div></div>
    <div class="cube"><div class="cube__inner"></div></div>
  </div>
}